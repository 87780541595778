* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.pageTitle{
  text-align: center;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(102, 98, 98);
  color: white;
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-around;
  background-color: rgb(102, 98, 98);
  align-items: center;
  list-style-type: none; /* Remove bullets */
  padding: 5; /* Remove padding */
  height: 60px;
  margin: 0; /* Remove margins */
}

.searchBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBar input{
  padding: 5px;
  font-size: 15px;
}


/* Style the counter cards */
.list-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: #f1f1f1;
  cursor: pointer;

}

.list-item:hover{
  color: rgb(69, 69, 145);
}
.site:hover{
  color: rgb(69, 69, 145);
}

  /* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

